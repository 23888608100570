
import Vue from "vue";
import NavBar from "@/components/navigation/NavBar.vue";
import Alert from "@/components/dialogs/Alert.vue";
import CallAlert from "@/components/dialogs/CallAlert.vue";
import Preloader from "@/components/dialogs/Preloader.vue";
import SideBar from "@/components/navigation/SideBar.vue";
import ThemeSettings from "@/components/settings/ThemeSettings.vue";
import CardModal from "@/components/dialogs/CardModal.vue";
import store from "@/store";

export default Vue.extend({
  name: "Default",

  components: {
    ThemeSettings,
    SideBar,
    Preloader,
    Alert,
    CallAlert,
    NavBar,
    CardModal
  },

  data: () => ({
    webSocket: null as any,
    wsUrl: "" as string,
    showAnswerText: false as boolean,
    showEndCall: false as boolean,
    activeCard: {} as any,
    answeredCard: null as any,
    dialog: false as boolean
  }),

  computed: {
    user() {
      return this.$store.getters["user/info"];
    }
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.showAnswerText = false;
        this.showEndCall = false;
      }
    }
  },

  async beforeMount() {
    await this.connectToSocket();
  },

  methods: {
    async getUser(): Promise<void> {
      try {
        const {
          app: { ws_url }
        } = await this.$API.users().getUser();

        this.wsUrl = ws_url;
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
    },
    async connectToSocket() {
      try {
        if (!this.wsUrl) {
          await this.getUser();
        }

        this.webSocket = new WebSocket(this.wsUrl);
        // this.webSocket = new WebSocket(
        //   "wss://connect.websocket.in/v3/1?api_key=oCdCMcMPQpbvNjUIzqtvF1d2X2okWpDQj4AwARJuAgtjhzKxVEjQU6IdCjwm&notify_self"
        // );

        this.webSocket.onmessage = async (data: any) => {
          if (!data?.data) {
            return;
          }

          const message = JSON.parse(data.data);
          const user = this.$store.getters["user/info"];

          if (message?.event === "answer") {
            await this.checkAnswerEvent(message);
            return;
          }

          if (message?.event === "hangup") {
            await this.checkHungUpEvent(message);
            return;
          }

          if (message?.data?.dst !== user.phone) {
            return;
          }

          if (message.event === "call") {
            await this.checkCallEvent(message);
            return;
          }
        };
      } catch (e) {
        console.log(e);
      }
    },
    async showCard(message: any): Promise<void> {
      await store.dispatch("callAlert/showInfo", {
        text: { ...message.data },
        withoutClosing: true,
        icon: "mdi-delete"
      });
    },
    async checkCallEvent(message: any): Promise<void> {
      if (this.dialog && this.activeCard?.src === message.data.src) {
        return;
      }

      if (message.data.dst !== this.user.phone) {
        return;
      }

      await this.showCard(message);
    },
    async checkAnswerEvent(message: any): Promise<void> {
      this.answeredCard = { ...message.data };

      if (this.activeCard?.src === message.data.src) {
        this.showAnswerText = true;
        this.showEndCall = false;
      }

      if (this.activeCard?.dst === message.data.dst) {
        await this.$store.dispatch("callAlert/update");
      } else {
        await this.$store.dispatch("callAlert/hide", message.data.src);
      }
    },
    async checkHungUpEvent(message: any): Promise<void> {
      this.showAnswerText = false;
      if (this.activeCard?.src !== message.data.src) {
        return;
      }

      if (this.activeCard?.dst === message.data.dst) {
        this.showEndCall = true;
      }

      this.$emit("update");
    },
    setActiveCard(card: any): void {
      this.activeCard = { ...card };

      if (this.activeCard.src === this.answeredCard?.src) {
        this.showAnswerText = true;
      }

      this.dialog = true;
    },
    setAnsweredCard(card: any): void {
      this.answeredCard = card;
    }
  }
});
