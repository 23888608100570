
import Vue from "vue";
import startCase from "lodash/fp/startCase";
import Profile from "@/components/navigation/Profile.vue";

export default Vue.extend({
  name: "NavBar",

  components: { Profile },

  data: () => ({
    startCase,
    showSearch: false as boolean
  }),

  computed: {
    moduleName() {
      const localizedTitle = `modules.${this.$route.meta?.module}`;

      if (localizedTitle.includes(".")) {
        return this.$t(localizedTitle);
      }
      return localizedTitle;
    }
  },

  methods: {
    toggleSideBar(): void {
      this.$store.dispatch("navigation/toggle");
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    }
  }
});
