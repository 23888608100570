export interface WebSocketInterface {
  create(url: string): void;

  instance(): WebSocket;
}

class WebSocketInstance implements WebSocketInterface {
  private WebSocket = null as any;

  public send(data: string) {
    this.WebSocket.send(data);
  }

  public create(url: string) {
    this.WebSocket = new WebSocket(url);
  }

  public instance(): WebSocket {
    return this.WebSocket;
  }
}

export default new WebSocketInstance();
