
import Vue from "vue";
import AlertHeaders from "../config/alert-headers.json";
import webSocket from "@/services/webSocket";

export default Vue.extend({
  name: "Alert",

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    user: {
      type: Object,
      default: () => ({}),
      required: false
    },
    ownerCard: {
      type: Object,
      default: () => ({}),
      required: false
    }
  },

  data: () => ({
    webSocket,
    AlertHeaders,
    fieldsModel: [
      {
        value: "dst",
        text: "Номер телефона",
        icon: "mdi-phone",
        cols: 6
      },
      {
        value: "date",
        text: "Дата первого появления",
        icon: "mdi-calendar",
        cols: 6
      },
      {
        value: "name",
        text: "Имя",
        icon: "mdi-account",
        cols: 6
      },
      {
        value: "redirected_to",
        text: "Перевели на",
        icon: "mdi-keyboard-return",
        cols: 6
      },
      { value: "comment", text: "Комментарии", icon: "mdi-comment", cols: 12 }
    ] as any,
    showExtraFields: [false] as Array<boolean>,
    parsedArr: [] as Array<any>,
    dialog: false as boolean,
    maxAllowedMessages: 4 as number
  }),

  computed: {
    messages(): Array<any> {
      return this.$store.getters["callAlert/messages"];
    },
    show(): boolean {
      return this.$store.getters["callAlert/show"];
    }
  },

  watch: {
    ownerCard(payload: any) {
      const messageIndex = this.messages.findIndex(
        (item: any) => item.text.src === payload.src
      );

      if (messageIndex !== -1) {
        if (this.user.phone !== payload.dst) {
          this.hide(messageIndex);
          return;
        }

        this.messages.forEach((item: any, index: number) => {
          item.text.isOwner = messageIndex === index;
        });
      } else {
        this.messages.forEach((item: any) => {
          item.text.isOwner = false;
        });
      }
      this.$forceUpdate();
    },
    value: {
      immediate: true,
      handler() {
        this.dialog = this.value;
      }
    },
    messages: {
      immediate: true,
      deep: true,
      handler() {
        if (this.messages.length > this.maxAllowedMessages) {
          const [lastMessage] = this.messages;
          this.hide(Number(!!lastMessage.text.isOwner));
        }
      }
    }
  },

  methods: {
    hide(index: number, cardId?: string): void {
      if (cardId) {
        this.$store.dispatch("callAlert/hide", { index, cardId });
        return;
      }
      this.$store.dispatch("callAlert/hide", index);
    },
    async saveAlert(message: any, index: number): Promise<void> {
      if (message.hasOwnProperty("onSave")) {
        await message.onSave();
      }
      this.$emit("setActiveCard", message.text);
      this.hide(index);
    },
    closeAlert(message: any, index: number): void {
      if (message.hasOwnProperty("onClose")) {
        message.onClose();
      }

      this.hide(index, message.text.cardId);
    }
  }
});
