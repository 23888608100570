
import Vue from "vue";

export default Vue.extend({
  name: "SideBar",

  data: () => ({
    logoImage: require("@/assets/images/logos/logo-icon.png"),
    logoText: require("@/assets/images/logos/logo-text.png"),
    items: [] as Array<MenuItemsInterface>
  }),

  computed: {
    expanded: {
      get(): boolean {
        return this.$store.getters["navigation/expanded"];
      },
      set(value: boolean): void {
        this.$store.dispatch("navigation/set", value);
      }
    }
  },

  mounted(): any {
    this.$nextTick(async () => {
      const response: any = await import(`../../static/data/menu.json`);

      this.items = (response.default as Array<MenuItemsInterface>) || [];
    });
  },

  methods: {
    async logout(): Promise<void> {
      try {
        await this.$store.dispatch("authentication/logout");
        await this.$router.push("/auth/login");
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e);
      }
    },
    close(value: boolean): void {
      if (!value) {
        this.$store.dispatch("navigation/set", value);
      }
    },
    toMain(): void {
      this.$router.push("/");
    }
  }
});
