
import Vue from "vue";

import DatePicker from "@/components/form-components/DatePicker.vue";
// import PhonePicker from "@/components/form-components/PhonePicker.vue";
import store from "@/store";

export default Vue.extend({
  name: "Alert",

  components: {
    // PhonePicker,
    DatePicker
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    showEndCall: {
      type: Boolean,
      default: false,
      required: false
    },
    showAnswerText: {
      type: Boolean,
      default: false,
      required: false
    },
    answeredCard: {
      type: Object,
      default: () => ({}),
      required: false
    },
    activeCard: {
      type: Object,
      default: () => ({} as any),
      required: false
    },
    user: {
      type: Object,
      default: () => ({}),
      required: false
    }
  },

  data: () => ({
    showTimerLocal: false as boolean,
    dialog: false as boolean,
    currentCard: {} as any,
    model: {} as any,
    selects: {} as any,
    types: {
      1: {
        icon: "mdi-comment",
        text: "Текстовое-поле"
      },
      2: {
        icon: "mdi-calendar",
        text: "Дата"
      },
      3: {
        icon: "mdi-menu",
        text: "Список"
      },
      4: {
        icon: "mdi-comment-text",
        text: "Комментарий"
      },
      5: {
        icon: "mdi-phone",
        text: "Номер телефона"
      }
    } as any,
    loading: true as boolean,
    timer: {
      hours: 0 as number,
      minutes: 0 as number,
      seconds: 0 as number
    } as any,
    timeInterval: null as any
  }),

  watch: {
    // async update() {
    //   if (this.update) {
    //     await this.loadData();
    //   }
    // },
    value: {
      immediate: true,
      async handler() {
        this.dialog = this.value;

        if (
          this.dialog &&
          this.activeCard?.src &&
          this.activeCard.src === this.currentCard.src
        ) {
          await this.loadData();
        }
      }
    },
    answeredCard: {
      deep: true,
      immediate: true,
      async handler() {
        if (
          this.activeCard?.src &&
          this.answeredCard?.src === this.activeCard.src &&
          this.activeCard.src === this.currentCard.id
        ) {
          await this.loadData();
        }
      }
    }
  },

  computed: {
    update() {
      return store.getters["callAlert/update"];
    },
    time() {
      const time = { minutes: "", seconds: "", hours: "" };
      if (this.timer.seconds < 10) {
        time.seconds = `0${this.timer.seconds}`;
      } else {
        time.seconds = this.timer.seconds;
      }

      if (this.timer.minutes < 10) {
        time.minutes = `0${this.timer.minutes}`;
      } else {
        time.minutes = this.timer.minutes;
      }

      if (this.timer.hours < 10) {
        time.hours = `0${this.timer.hours}`;
      } else {
        time.hours = this.timer.hours;
      }
      if (this.timer.hours > 0) {
        return `${time.hours}:${time.minutes}:${time.seconds}`;
      }

      return `${time.minutes}:${time.seconds}`;
    },
    isOwner(): boolean {
      return (
        this.answeredCard &&
        this.answeredCard.src === this.currentCard.src &&
        (this.answeredCard.dst === this.user.phone ||
          this.currentCard.operator_id === this.user.phone)
      );
    },
    showIncomingCall(): boolean {
      return (
        Number(this.activeCard?.dst) === Number(this.user.phone) &&
        this.showTimerLocal
      );
    }
  },

  async mounted() {
    await this.loadData();
    this.timeInterval = setInterval(() => {
      this.startTimer();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.timeInterval);
  },

  methods: {
    startTimer(): void {
      if (this.timer.seconds < 60) {
        this.timer.seconds++;
        return;
      }

      if (this.timer.seconds === 60) {
        this.timer.minutes++;
        this.timer.seconds = 0;
      }

      if (this.timer.minutes === 60) {
        this.timer.hours++;
        this.timer.minutes = 0;
      }
    },
    async loadData() {
      if (!this.activeCard.src) {
        return;
      }

      this.loading = true;
      try {
        const response = await this.$API
          .clients()
          .getClient(this.activeCard.src);
        this.currentCard = { ...response, src: this.activeCard.src };
      } catch (e) {
        if (e?.error) {
          const card = await this.$API
            .cards()
            .getClientCardById(this.activeCard.catalog);

          this.currentCard = {
            ...card,
            id: this.activeCard.src,
            src: this.activeCard.src,
            fields: card.data.map((item: any) => {
              if (item.type === "5") {
                item.value = this.activeCard.src;
              }

              return {
                ...item,
                card: item
              };
            })
          };
        }
      }

      if (
        this.answeredCard?.dst === this.user.phone &&
        this.answeredCard.src === this.activeCard?.src
      ) {
        await this.pauseOperator(this.user.phone);
      }

      this.loading = false;
    },
    toggleShowIncomingCall(value: boolean): void {
      this.showTimerLocal = value;
    },
    async pauseOperator(id: number) {
      try {
        await this.$API.operators().pause(id);
      } catch (e) {
        await this.$store.dispatch("error/showError", e.message);
      }
    },
    async unpauseOperator(id: number) {
      try {
        await this.$API.operators().unpause(id);
      } catch (e) {
        await this.$store.dispatch("error/showError", e.message);
      }
    },
    async closeDialog() {
      try {
        if (Number(this.currentCard.operator_id) === Number(this.user.phone)) {
          await this.unpauseOperator(this.user.phone);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }

      if (this.answeredCard?.src === this.activeCard?.src) {
        await this.$emit("setAnsweredCard", {});
      }

      await this.unpauseOperator(this.user.phone);
      this.$emit("input", false);
    },
    async submit() {
      try {
        const form = this.$refs.form as Vue;

        if (form.validate()) {
          await this.$API.clients().edit(this.currentCard.src, {
            fields: this.currentCard.fields.reduce((result: any, item: any) => {
              result[`field${item.card.id}`] = item.value;
              return result;
            }, {})
          });

          form.removeGuard();
          await this.$store.dispatch(
            "alert/showSuccess",
            "Данные клиента успешно сохранены"
          );

          await this.closeDialog();
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
